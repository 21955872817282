import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Temp11 from './component/Temp11';

function App() {
  return (
    <Router>
      <div className="">
        <Routes>
          <Route path="/thalof" element={<Temp11 />} />
          {/* You can add more routes here */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
