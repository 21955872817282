import React from "react";
import img from "../assets/backgroundblue.png";
import casse from "../assets/casse.png";
import tele from "../assets/TELEPHONE.png";
import WHATSUP from "../assets/WHATSUP.png";
import WEBSITE from "../assets/WEBSITE.png";
import LOCATION from "../assets/LOCATION.png";
import snap from "../assets/SNAPChat.png";
import X from "../assets/X.png";
import instagram from "../assets/instagram.png";
import axios from "axios";

const Temp11 = () => {
  const toDataURL = (url) =>
    axios
      .get(url, {
        responseType: "blob", // Important to handle the response as a Blob
        headers: {},
      })
      .then((response) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(response.data);
        });
      })
      .catch((error) => {
        console.error("Error in toDataURL with axios:", error);
        return null;
      });
  const saveToContacts = async () => {
    const contact = {
      name: "المحامي رغدان رأفت عبد القادر",
      mobile: "+966509155558" || "",
      organization:  "شركة تحالف للمحاماة",
      address: "https://maps.app.goo.gl/BHypRkpm1CwZ1oiz7?g_st=iw" || "",
    };

    let imageDataUrl = "";

    let vcard =
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `N: المحامي رغدان رأفت عبد القادر\n` +
      `TEL;CELL:+966509155558\n` +
      `ORG;WORK:شركة تحالف للمحاماة\n` +
      `ADR;HOME:https://maps.app.goo.gl/BHypRkpm1CwZ1oiz7?g_st=iw\n`;

   
    vcard += `END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    // Create a hidden download link and trigger it
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `tahalof.vcf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <div className="flex justify-center">
    <div className="max-w-[450px]">
      <div className="relative overflow-hidden">
        <div className="absolute top-[25vh]  right-0 w-full z-30 p-5">
          <div className="flex flex-col items-end">
            <div className="flex flex-col items-end justify-start mt-10 text-end mr-3">
              <h1 className="text-white font-bold text-[23px]">
                شركة تحالف للمحاماة
              </h1>
              <h1 className="text-white">المحامي رغدان رأفت عبد القادر</h1>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between gap-2 mt-8 ">
            <div className="w-[160px] py-1 px-2 my-auto rounded-md bg-white">
              <div
                className="flex items-center gap-1"
                onClick={() => {
                  window.open(`tel:+966509155558`);
                }}
              >
                <img src={tele} alt="" className="w-5 h-5" />
                <p className="font-bold text-[#085171]">TELEPHONE</p>
              </div>
            </div>
            <div
              className="w-[160px] py-1 px-2 my-auto rounded-md bg-white"
              onClick={() => {
                window.open(`https://wa.me/+966509155558`);
              }}
            >
              <div className="flex items-center gap-1">
                <img src={WHATSUP} alt="" className="w-5 h-5" />
                <p className="font-bold text-[#085171]">WHATSUP</p>
              </div>
            </div>
            <div className="w-[160px] py-1 px-2 my-auto rounded-md bg-white">
              <div
                className="flex items-center gap-1"
                onClick={()=>{window.open("https://www.alliance-corp.com/contact/")}}
              >
                <img src={WEBSITE} alt="" className="w-5 h-5" />
                <p className="font-bold text-[#085171]">WEBSITE</p>
              </div>
            </div>
            <div className="w-[160px] py-1 px-2 my-auto rounded-md bg-white">
              <div
                className="flex items-center gap-1"
                onClick={()=>{window.open(
                    "https://maps.app.goo.gl/BHypRkpm1CwZ1oiz7?g_st=iw"
                  )}}
              >
                <img src={LOCATION} alt="" className="w-5 h-5" />
                <p className="font-bold text-[#085171] whitespace-nowrap">
                  OUR LOCATION
                </p>
              </div>
            </div>
            <div className="flex justify-between gap-5 items-center mt-4">
              <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Falliancelawsa">
                <div
                  className="w-fit py-2 px-2 my-auto rounded-full bg-[#085171] "
                  style={{
                    backdropFilter: "blur(8px)",
                    boxShadow:
                      "0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <img src={X} alt="" className="w-6 h-6" />
                </div>
              </a>
              <a href="https://www.instagram.com/alliancecorp.sa/?hl=en">
                <div
                  className="w-fit py-2 px-2 my-auto rounded-full bg-[#085171] "
                  style={{
                    backdropFilter: "blur(8px)",
                    boxShadow:
                      "0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <img src={instagram} alt="" className="w-6 h-6" />
                </div>
              </a>
              <a href="https://www.snapchat.com/add/alliancelaw.sa?invite_id=VteILUKF&locale=en_SA%40calendar%3Dgregorian&share_id=q6sLLD4SQsiCT_0NEUA5hg&sid=ad17d25ac29c4d63b10330674c69b9d3">
                <div
                  className="w-fit py-2 px-2 my-auto rounded-full bg-[#085171] "
                  style={{
                    backdropFilter: "blur(8px)",
                    boxShadow:
                      "0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <img src={snap} alt="" className="w-6 h-6" />
                </div>
              </a>
            </div>
            <div className="flex flex-col justify-between gap-1 mx-auto mt-2">
              <div className="w-10 h-[1px] bg-white"></div>
              <div className="w-5 h-[0.5px] bg-white mx-auto"></div>
            </div>
            <div
              className="w-fit py-1 px-2 my-auto rounded-md bg-[#085171] mt-3"
              style={{
                backdropFilter: "blur(8px)",
                boxShadow:
                  "0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.3)",
              }}
              onClick={saveToContacts}
            >
              <p className="text-base font-bold text-white">ADD TO CONTACT</p>
            </div>
          </div>
        </div>
        <div className=" -z-10">
          <img src={img} alt="" className=" " />
        </div>
      </div>
    </div>
    </div>
  );
};

export default Temp11;
